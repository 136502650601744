<template>
  <li
    class="nav-item d-flex justify-content-between"
    :class="{
      'active': isActive,
      'disabled': item.disabled,
      // 'font-weight-bold': $store.state.themeEditor.hoveredSectionId
    }"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <b-link
      class="d-flex align-items-center flex-grow-1"
      @click="$emit('click', item)"
    >
      <material-icon
        v-if="item.iconMaterial"
        :icon="item.iconMaterial"
      />
      <feather-icon
        v-else
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        :id="item.tagId || null"
        :class="{'d-none': !item.tag || parseInt(item.tag)<1}"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ $t(item.tag) }}
      </b-badge>
    </b-link>

    <template
      v-if="!['header', 'footer'].includes(item.code) && item.isEditable"
    >
      <b-button
        v-show="isHover"
        :variant="item.isActive ? 'flat-warning' : 'flat-success'"
        class="btn-icon rounded-circle mr-25"
        style="width: 44px"
        @click="$emit('clickVisibilityToggle', !item.isActive)"
      >
        <feather-icon :icon="item.isActive ? `EyeOffIcon` : `EyeIcon`" />
      </b-button>

      <b-button
        v-show="isHover"
        :variant="isFirstItem ? `flat-secondary` : `flat-info`"
        class="btn-icon rounded-circle mr-25"
        style="width: 44px"
        :disabled="isFirstItem"
        @click="$emit('clickMoveUp')"
      >
        <feather-icon icon="ArrowUpIcon" />
      </b-button>

      <b-button
        v-show="isHover"
        :variant="isLastItem ? `flat-secondary` : `flat-info`"
        class="btn-icon rounded-circle mr-25"
        style="width: 44px"
        :disabled="isLastItem"
        @click="$emit('clickMoveDown')"
      >
        <feather-icon icon="ArrowDownIcon" />
      </b-button>

      <b-button
        v-show="isHover"
        variant="flat-danger"
        class="btn-icon rounded-circle mr-1"
        style="width: 44px"
        @click="$emit('clickDelete')"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
    </template>
  </li>
</template>

<script>
import { BLink, BBadge, BButton } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from 'vue'
import useEditorNavMenuLink from './useEditorNavMenuLink'
import mixinEditorNavMenuLink from './mixinEditorNavMenuLink'

export default {
  components: {
    BButton,
    BLink,
    BBadge,
  },
  mixins: [mixinEditorNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isFirstItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useEditorNavMenuLink(props.item)
    const { t } = useI18nUtils()

    const isHover = ref(false)

    return {
      isHover,
      isActive,
      linkProps,
      updateIsActive,

      // i18n
      t,
    }
  },

}
</script>

<style>

[dir=ltr] .navigation-main .material-symbols-outlined {
  margin-right: 1.1rem;
}
[dir=rtl] .navigation-main .material-symbols-outlined {
  margin-left: 1.1rem;
}
</style>
