<template>
  <div>
    <ul>
      <editor-nav-menu-add-item-divider
        v-if="allowAddition"
        :key="`top-add-item-divider`"
        @clickAddSection="() => onAddSectionClick(null)"
      />
      <template
        v-for="(item, index) in items"
      >
        <component
          :is="resolveNavItemComponent(item)"
          :key="item.header || item.id || item.title"
          :item="item"
          :is-first-item="index === 0"
          :is-last-item="index === items.length - 1"
          @click="item => $emit('click', item)"
          @clickVisibilityToggle="v => $store.dispatch('themeEditor/updateSectionVisibility', {id: item.id, is_active: v})"
          @clickMoveUp="v => $store.dispatch('themeEditor/changeDisplayOrder', {id: item.id, display_order_change: 'up'})"
          @clickMoveDown="v => $store.dispatch('themeEditor/changeDisplayOrder', {id: item.id, display_order_change: 'down'})"
          @clickDelete="onDeleteSectionClick(item.id)"
        />

        <li
          v-if="!allowAddition && items.length && (index < items.length - 1)"
          :key="`${item.id || item.header}-section`"
          class="nav-item"
        >
          <hr>
        </li>

        <editor-nav-menu-add-item-divider
          v-if="allowAddition && (item.code !== 'footer') && !item.header"
          :key="`${item.id || item.header}-add-item-divider`"
          @clickAddSection="() => onAddSectionClick(item.id)"
        />
      </template>
    </ul>

    <!-- modal login-->
    <b-modal
      id="add-section"
      v-model="isAddSectionModalVisible"
      cancel-variant="outline-secondary"
      :ok-title="$t('Add')"
      :cancel-title="$t('Cancel')"
      centered
      :title="$t('theme_editor.add_section_modal.title')"
      :ok-disabled="!addingSectionId"
      no-close-on-backdrop
      @ok.prevent="onAddSectionSubmit"
      @hide="addingSectionId = null"
    >
      <i-input
        id="section"
        v-model="addingSectionId"
        type="select"
        title="theme_editor.add_section_modal.input"
        placeholder="theme_editor.add_section_modal.input"
        :options="$store.state.themeEditor.sectionOptions"
        label="name"
        required
        :state="false"
        :validation-rules="['required']"
      />
    </b-modal>
  </div>
</template>

<script>
import { resolveEditorNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from 'vue'
import EditorNavMenuAddItemDivider
from '@core/layouts/layout-editor/components/editor-nav-menu/components/editor-nav-menu-items/EditorNavMenuAddItemDivider.vue'
import { BLink, BModal } from 'bootstrap-vue'
import store from '@/store'
import { handleBadRequestError, handleValidationError } from '@core/utils/utils'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
import EditorNavMenuHeader from '../editor-nav-menu-header'
import EditorNavMenuLink from '../editor-nav-menu-link/EditorNavMenuLink.vue'
import EditorNavMenuGroup from '../editor-nav-menu-group/EditorNavMenuGroup.vue'

export default {
  components: {
    BLink,
    BModal,
    EditorNavMenuAddItemDivider,
    EditorNavMenuHeader,
    EditorNavMenuLink,
    EditorNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    allowAddition: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    const isAddSectionModalVisible = ref(false)
    const addingSectionAfter = ref(null)
    const addingSectionId = ref(null)

    const onAddSectionClick = (after = null) => {
      isAddSectionModalVisible.value = true
      addingSectionAfter.value = after
      if (addingSectionAfter.value === 'header') {
        addingSectionAfter.value = null
      }
    }

    const onAddSectionSubmit = () => {
      store.dispatch('themeEditor/addSection', {
        theme_section_id: addingSectionId.value.id,
        after: addingSectionAfter.value,
      }).then(() => {
        isAddSectionModalVisible.value = false
      }).catch(e => {
        if (!handleValidationError(e)) {
          handleBadRequestError(e)
        }
      })
    }

    const {
      deleteItem,
    } = useModelDelete(
      'themeEditor',
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
      },
      'deleteSection',
    )

    const onDeleteSectionClick = sectionId => {
      deleteItem(sectionId)
    }

    return {
      resolveNavItemComponent,
      isAddSectionModalVisible,
      addingSectionId,
      onAddSectionClick,
      onAddSectionSubmit,
      onDeleteSectionClick,
    }
  },
}
</script>
